/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";

import { GlobalDataContext } from "../../context/context";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css"; 



const Map = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
      <Tabs>          
        <TabList>           
           {rpdata?.dbPrincipal?.location?.map((item, index) => {              
            return <Tab key={index}>{item.address}</Tab>;           
             })}          
             </TabList>          
             {rpdata?.dbPrincipal?.location?.map((item, index) => {              
              return <TabPanel key={index}><iframe             
              loading="lazy"              
              title="Cover Locations"             
               src={item.url}             
                style={{ width: "100%", height: "400px" }}           
                 /></TabPanel>           
                  })}        
                  </Tabs>
      </div>
    </div>
  )
}

export default Map
